import { createStore } from 'vuex'
import { content } from './content'

export default createStore({
  state: {
    page: 'homePage',
    language: 'ro'
  },
  getters: {
    sectionContent: (state) => (section) => content[state.page][state.language][section],
  },
  mutations: {
    SET_LANGUAGE (state, language) {
      state.language = language
    }
  },
  actions: {
  },
  modules: {
  }
})
