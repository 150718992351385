<template>
  <div class="audio">
    <audio src="/audio.mp3" ref="audioElement" autoplay type="audio/mp3" volume="0.3" controls />
  </div>
</template>

<script setup>

</script>

<style lang="scss">
  .audio {
    audio {
      border: 1px solid #353535;
    }
  }
</style>