<template>
  <div class="language-bar flex"
       :class="{'bar-open': isBarOpen}">
    <button v-for="({lang, icon}, index) in languages"
            :key="index"
            class="flex-center"
            :class="{selected: language === lang}"
            @click="handleLanguage(lang)">
      <img :src="icon"
           :alt="lang" />
    </button>
    <i @click="toggleBar">
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.15706 7.71102L1.50006 13.368L0.0860596 11.954L5.03606 7.00401L0.0860596 2.05401L1.50006 0.640015L7.15706 6.29701C7.34453 6.48454 7.44985 6.73885 7.44985 7.00401C7.44985 7.26918 7.34453 7.52349 7.15706 7.71102Z" fill="black"/>
      </svg>
    </i>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed, onMounted, ref} from "vue";
  import ro from '../assets/flags/ro.svg'
  import en from '../assets/flags/en.svg'
  import fr from '../assets/flags/fr.svg'
  // import de from '../assets/flags/de.svg'

  const { state, dispatch, commit } = useStore()
  const isBarOpen = ref(false);
  const language = computed(() => state.language)
  const languages = [
    {lang: 'ro', icon: ro},
    {lang: 'en', icon: en},
    {lang: 'fr', icon: fr},
    // {lang: 'de', icon: de},
  ]

  function toggleBar () {
    isBarOpen.value = !isBarOpen.value
  }

  function handleLanguage (lang) {
    commit('SET_LANGUAGE', lang)
    setTimeout(() => {
      isBarOpen.value = false;
    }, 200)
  }
</script>

<style lang="scss">
  .language-bar {
    @media only screen and (min-width: 0) {
      padding: 8px 10px 8px 12px;
      left: 0;
      bottom: 120px;
      background-color: #e4e4e4;
      position: fixed;
      border: 1px solid lightgray;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      transform: translateX(-130px);
      transition: transform .3s ease-in-out;
      button {
        &:not(:last-child) {
          margin-right: 12px;
        }
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        opacity: 0.6;
        img {
          height: 22px;
          border-radius: 4px;
        }
        &.selected {
          opacity: 1;
        }
      }
      &.bar-open {
        transform: translateX(0)!important;
        i svg {
          transform: rotate(180deg);
        }
      }
    }

    @media only screen and (min-width: 768px) {
      height: 40px;
      transform: translateY(48px);
      position: absolute;
      right: 0;
      top: 0;
      background-color: transparent;
      border: none;
      button {
        &:first-child {
          margin-left: auto;
        }
        img {
          height: 16px;
          border-radius: 4px;
        }
      }
      i {
        display: none;
      }
    }
  }
</style>