export const content = {
  homePage: {
    ro: {
      about: {
        mainTitle: 'Textural Music Festival',
        title: ' Despre Textural',
        p1: 'Festivalul TEXTURAL a fost inițiat de doi muziceni de nișă din București, Andrei Bălan și Călin Torsan. Primul este și sculptor, iar celălat este și scriitor. Ideea lor a fost crearea unui spațiu de manifestare pentru muzicieni similari, cu preocupări constante pentru experimentul sonor.',
        p2: 'Prima ediție TEXTURAL a fost găzduită de Muzeul-Atelier Școala din Piscu, județul Ilfov. Evenimentul s-a desfășurat în intervalul 18 - 19 noiembrie 2022 și a reunit nouă muzicieni. Cu aceeași ocazie s-a impus un detaliu specific festivalului: tragerea la sorți a formulelor instrumentale din fiecare seară.',
        p3: 'Următoarele două ediții, cea din 2023 și spring TEXTURAL 2024, au fost găzduite de Zadar Bar, un bar bucureștean dedicat muzicilor experimentale și celor liber improvizate.',
      },
      program: {
        title: `TEXTURAL 4`,
        date: `(31 octombrie - 3 noiembrie, 2024)`,
        location: `la ZADAR BAR`,
        days: [
          {date: `joi, 31 octombrie`, bands: `Ariadna Ene Iliescu (violă, voce) </br> Aram Shelton (sax) </br> Juli Dima (chitară) </br> Marius Achim (tobe)`},
          {date: `vineri, 1 noiembrie`, bands: `Sian Brie (chitară) </br> Bogdan Frigioiu (chitară) </br> Sorin Romanescu (chitară) </br> Neon Cola (electronice)`},
          {date: `sâmbătă, 2 noiembrie`, bands: `Shanyio (instrumente exotice cu coarde, preparate) </br> Andrei Raicu (electronice) </br> Medoia (chitară) </br> Ati (percuție)`},
          {date: `duminică, 3 noiembrie`, bands: `Irinel Anghel &amp; TEXTURAL ORCHESTRA`},
        ]
      },
      zadar: {
        title: 'Zadar Bar',
        p1: 'Două ediții ale festivalului TEXTURAL au fost găzduite de Zadar Bar: cea de la sfârșitul anului 2023 și springTEXTURAL, o ediție specială de an bisect (2024). Reconfigurat pe structura mai veche a fostului Geo Bar, locul este visul și realizarea a doi melomani împătimiți de zona muzicilor neconvenționale: Victor Hariton și Vali Neagu.',
        p2: 'Tot ce înseamnă muzică Zadar, adică scena și ambianța din timpul săptămânii, are legătură cu experimentul, cu ineditul, cu improvizația și cu un spirit care propune libertatea de exprimare și toleranța.',
        p3: 'Zadar Bar se află pe strada Mircea Vulcănescu din București. La numărul 23. Concertele se țin înăuntru, maxim până la ora 22:00, dar există și o curte cu destule mese, unde muzica se poate asculta prin intermediul unei instalații de sunet. Barul a devenit principalul loc de întâlnire a boemei bucureștene, dar și un soi de kilometru zero al artiștilor de nișă. Un fapt inedit: chiar și unul dintre barmani este un artist hip-hop și rap foarte apreciat.',
        p4: 'În sezonul rece concertele se țin lanț, ritmul scăzând în timpul verii, iar foarte mulți artiști străini aflați în București caută să își aranjeze un show în acest loc.',
        p5: 'Pe 24 august 2023, Zadar Bar a fost distins cu trofeul Jurjac Enescu, o statuetă care poate fi văzută pe unul dintre rafturile barului și care a punctat rolul lui de deschizător al noilor drumuri muzicale din România.',
      },
      texturalArtists: {
        title: 'Textural Artists',
        artists: [
          {
            name: 'Alexandru Hegyesi',
            about: 'Alexandru Hegyesi este un multi-instrumentist autodidact timișorean. Cântă la țambal, kantele, dulcimer, țiteră transilvană &amp; bavareză etc. A început muzica în 1992, la chitară bas și percuție.',
            image: './images/1.jpg',
            link: 'https://www.youtube.com/watch?v=9hwfwTPwEYA&amp;t=950s',
            email: 'alexhegyesi74@gmail.com'
          },
          {
            name: 'Andrei Bălan',
            about: 'Andrei Bălan este unul dintre inițiatorii Festivalului TEXTURAL. Deopotrivă sculptor și muzician, el improvizează cu chitara și cu electronicele, creând peisaje cinematice de sunet, drone, densități și forme sonore strălucitoare. Este inventatorul unui instrument muzical sculptural care îi poartă numele: Bălanofon.',
            image: './images/2.jpg',
            link: 'https://www.youtube.com/watch?v=xelvrCh2Fec',
            email: 'andreibalan.art@gmail.com'
          },
          {
            name: ' Aram Shelton',
            about: 'Aram Shelton is an improviser and composer who performs on saxophone, clarinets and live electronics. With a distinct, harmonically rich sound, he develops musical thoughts through constant motion, clear melodic and rhythmic constructs, tonal shifts and complex sounds. His vocabulary utilizes a wide range of extended techniques including multiphonics and harmonics, circular breathing, wind and an exploration of the outer ranges of the instrument.Shelton has been a member of the creative music communities in the San Francisco Bay Area, Chicago, Copenhagen and Budapest. His playing and music appears on more than two dozen albums on labels including Clean Feed, Delmark, Not Two, Astral Spirits and his own Singlespeed Music.',
            image: './images/3.jpg',
            link: 'https://downtownmusic.net/aram-shelton',
            email: 'aramshelton@gmail.com'
          },
          {
            name: 'Bogdan Frigioiu',
            about: `Chitarist și vocalist gutural, cunoscut sub mai multe pseudonime ca Jay sau Spurcăciune, pentru proiectele Rotheads, Void Forger, Cold Brats și Keeng Ra'at.`,
            image: './images/4.jpg',
            link: 'https://www.youtube.com/watch?v=wXq-YZz4fLs',
            email: 'Bfrigioiu@gmail.com'
          },
          {
            name: 'Călin Torsan',
            about: 'Unul dintre inițiatorii Festivalului TEXTURAL. Improvizează la instrumente de suflat, în diferite formule de expresie și orientare artistică. A publicat mai multe cărți de literatură.',
            image: './images/5.jpeg',
            link: 'https://www.youtube.com/watch?v=RTwoIIl7r4k',
            email: 'calintorsan@gmail.com'
          },
          {
            name: 'Cătălin Medoia',
            about: 'Trecând foarte timpuriu de la inginer la antreprenor, și apoi la om de marketing, Cătălin Medoia a fost mereu activ și în zona muzicală, fiind printre altele: organizator de concerte și festivaluri de rock, gazda multor seri de jam-session și membru în juriile unor concursuri de formații rock. Dintotdeauna pasionat de muzică, Medoia a fost și este un admirator și promotor al cosmosului sonor (și nu numai) al lui Frank Zappa.',
            about2: 'Adoră neconvenționalul, unicitatea happeningului, experimentul și muzica improvizată. Medoia nu se consideră un chitarist, ci o persoană care se folosește de chitara electrică pentru a- și exprima spontan gândurile muzicale.',
            image: './images/6.jpg',
            link: 'https://soundcloud.com/neoncola/agree-to-disagree-live-mixtape-time-is-an-agreed-upon-construct-part-1-27-apr-zadar?ref=clipboard&amp;p=a&amp;c=0&amp;si=227d474ad9f244f38d98d68b22dcb3a3&amp;utm_source=clipboard&amp;utm_medium=text&amp;utm_campaign=social_sharing',
            email: 'catalin.medoia@gmail.com'
          },
          {
            name: 'Irinel Anghel',
            about: 'Compozitoare, performer vocal, artist interdisciplinar (n. București, 1969). A absolvit cursurile Liceului de Muzică „George Enescu” din București, secţia pian (1988) şi pe cele ale Universităţii Naționale de Muzică din București, secţia Muzicologie (1994) şi secţia Compoziție (1996). Master în Muzicologie (1995) şi Compoziţie (1997), Irinel Anghel este doctor în ştiinţe muzicale din 2003 și absolventă a programului postdoctoral (MIDAS) în cadrul UNMB (2013).',
            about2: 'Creaţia ei muzicală este compusă din lucrări instrumentale, muzică de cameră, electronică, lucrări simfonice şi muzică de teatru. În ultimul timp, activitatea ei este dedicată muzicii improvizate, evenimentelor interdisciplinare, hibride, de fuziune si performance art-ului. Este adepta artei experiențiale cu detente suprarealiste.',
            image: './images/7.jpg',
            link: 'https://www.youtube.com/watch?v=I5ntKszoTgU',
            email: 'irinel.anghel@gmail.com'
          },
          {
            name: 'Ariadna Ene-Iliescu',
            about: `Ariadna Ene-Iliescu a studiat compoziție și dirijat de orchestră la Universitatea Națională de
Muzică București. Cântă la pian, uneori la violă, vioară și la alte instrumente. Muzica i-a fost aproape
de când se știe și reprezintă pentru ea atât un mod de exprimare, cât și un teren de căutări, experiment
și joacă. Explorează improvizația singură sau în cadrul colectivelor „Al Ehtifal” și „The Mad
Haterpillars”. Începând din 2022, desfășoară programul educațional „Laboratorul de teatru muzical”, în
care elevi de gimnaziu sunt provocați să improvizeze și să creeze muzical și dramatic.`,
            about2: '',
            image: './images/ariadna-ene.jpg',
            link: '',
            email: 'ariadnaei@yahoo.com'
          },
          {
            name: 'Iulian Dima',
            about: 'M-am apucat de chitară la 16 ani, iar de atunci muzica a fost ca o călătorie plină de aventuri și neprevăzut, de dezamăgiri crunte, dar și de momente magice pe care nu ți le poate oferi nici o altă formă de artă. Muzica mi-a oferit șansa de a cunoaște oameni talentati, cu povești grozave. Nu regret nimic, aș lua-o oricând de la capăt, ba chiar aș recomanda tuturor părinților să-și trimită copiii să învețe un instrument muzical, oricare ar fi el. Muzica este o experiență ce îți deschide mintea, îți alină sufletul și îți dezlănțuie imaginația și creativitatea.',
            image: './images/8.jpeg',
            link: 'https://www.youtube.com/watch?v=tss6ZZPbWHE',
            email: '77julian@gmail.com'
          },
          {
            name: 'Juan Carlos Negretti',
            about: 'Având muzica și arhitectura în centrul preocupărilor sale și acumulând peste 20 ani de experiență ca percuționist și baterist, Juan Carlos poate fi descris ca un arhitect al ritmurilor. În stilul său de interpretare, ludic și eclectic, ascultătorul va putea remarca influențele native afro-caraibe, care se contopesc cu cele balcanice și orientale, împreună cu deschiderea către zona experimentală.',
            image: './images/9.jpg',
            link: 'https://www.youtube.com/watch?v=nJjxr-SGPao',
            email: 'juannegretti@hotmail.com'
          },
          {
            name: 'Marius Achim',
            about: 'A început activitatea artistică în 1987, ca toboșar în trupa liceului. După 1989, a colaborat cu diferite proiecte muzicale de country, rock, hard rock, metal, jazz și improvizație liberă.',
            image: './images/10.jpg',
            link: 'https://www.youtube.com/watch?v=CdAmzepCyPw',
            email: 'Achim180@gmail.com'
          },
          {
            name: 'Mihai Iordache',
            about: `Saxofonist, compozitor. <br><br>
            1987 - debut la Festivalul de jazz Costinești, la blockflöte. <br>
            1987-1989 - lecții de improvizație cu pianistul <b>Marius Popp</b>. <br>
            1990 - primul saxofon decent, primul grup de jazz cu <b>Pedro Negrescu</b> - contrabas, <b>Lucian Maxim</b> - baterie. Lecții cu saxofonistul <b>Garbis Dedeian</b>. <br>
            1993-1999 - încercare de a străpunge blocada muzicii pop-rock alături de formația <b>Sarmalele Reci</b>. Albume: 1995, 1996, 1999. <br>
            1999-2003 - angajamente alimentare. <br>
            1999 - albumul Axis Mundi, cu <b>Harry Tavitian</b> și Orient Express. <br>
            2003 - primul album propriu de jazz: Friday, featuring <b>Tom Smith</b> <br>
            2003 - cooptat în formația <b>Kumm</b>. <br>
            2019 - cooptat în <b>Ploiești Big Band Jazz Society</b>, la saxofon bariton <br>
            2004, 2005, 2012, 2013, 2014, 2019, 2022 - albume proprii de jazz. <br>
            2004, 2006, 2009, 2015 - albume alături de <b>Kumm</b>.
          `,
            image: './images/11.jpg',
            link: 'https://www.youtube.com/watch?v=UApvn5poBLM',
            email: 'iordachemihaidan@gmail.com'
          },
          {
            name: 'Teodor Horea',
            about: 'Sub pseudonimul Neon Cola, Teodor Horea este compozitor de muzică de film, muzică electronică, electroacustică și spectrală, sintezist și improvizator live de free jazz și progressive rock la pian și sintetizatoare, sound designer și producător în nișa muzicii experimentale, demarând în prezent constituirea unui studio de înregistrări dedicat.',
            image: './images/12.jpg',
            link: 'https://soundcloud.com/neoncola/agree-to-disagree-live-mixtape-time-is-an-agreed-upon-construct-part-1-27-apr-zadar?ref=clipboard&amp;p=a&amp;c=1&amp;si=40f9fb1c6100499ea1ac9f295cf70477&amp;utm_source=clipboard&amp;utm_medium=text&amp;utm_campaign=social_sharing',
            email: 'hr_teodor@yahoo.com'
          },
          {
            name: 'Sian Brie',
            about: 'După ce studiază la Conservatorul din București, unde este inițiat în jazz și free jazz de pianistul Mircea Tiberian, Sian Brie se mută la Berlin. Aici are ocazia să cunoască și să colaboreze cu importanți muzicieni de pe scena berlineză, cum ar fi Christian Lillinger, Horst Nonnenmacher sau Manuel Miethe. După un an la Berlin, Sian Brie pleacă la Londra, unde îl cunoaște pe trompetistul polonez Dawid Frydryk. Cei doi descoperă că bariera lingvistică poate fi depășită prin vocabularul comun al experienței de expat venit din fostul bloc comunist. Muzicienii întâmpină atât discriminare negativă, cât și pozitivă și în final conștientizează faptul că au ajuns să aibă un comportament în perfectă conformitate cu eticheta care le-a fost pusă, de aici luând naștere atitudinea exprimată prin fraza “to live up to your discrimination standards”. Sian Brie și Dawid Frydryk formează un trio de muzică improvizată alături de Guillaume Viltard. Totodată, Sian Brie este fondator al grupului de avangardă UMAMI, care îi include pe John Edwards, Mark Sanders și Dawid Frydryk, și este membru al formației de muzică experimentală din București, And then This!. Printre alți muzicieni cu care colaborează se numără Mircea Tiberian, Maurice de Martin, Michael Griener, Mihai Iordache etc.',
            image: './images/13.jpg',
            link: 'https://www.youtube.com/watch?v=v53WP11-RwI',
            email: 'sianbrie@gmail.com'
          },
          {
            name: 'Sorin Romanescu',
            about: 'Debutul său ca muzician de jazz a avut loc la începutul anilor ‘90. După o scurtă activitate concertistică, în urma absolvirii unui curs de chitară clasică la Școala Populară de Artă din București și după absolvirea cursului de improvizație al pianistului Marius Popp, de la aceeași școală, a decis să-și dedice activitatea, în primul rând, muzicii de jazz și improvizației. De-a lungul carierei a cântat cu muzicieni din toate generațiile, de la pianistul Marius Popp și saxofonistul Garbis Dedeian (colaborarea cu acesta l-a consacrat ca muzician de jazz), până la Luiza Zan, Maria Răducanu, Mihai Iordache, Vlad Popescu, Cristian Soleanu și mulți alții. Manifestând flexibilitate stilistică și având o mare pasiune pentru improvizație, a avut la începutul anilor 2000 colaborări cu artiști coregrafi din zona dansului contemporan, precum Vava Ștefănescu, Eduard Gabia sau Mihai Mihalcea. De o bună perioadă de timp concertează și înregistrează pe albumele lui Alexandru Andrieș. A fost producător audio pentru muzică de publicitate, a realizat câteva coloane sonore de film documentar și coloana sonoră a filmului Câini.',
            image: './images/14.jpeg',
            link: 'https://www.youtube.com/watch?app=desktop&amp;v=c-cRbkUmPv4&amp;t=194s',
            email: 'sorinromanescu@gmail.com'
          },
          {
            name: 'Mihai Vasiu',
            about: `Mihai Vasiu, nepot al etnomuzicologului Caius Lepa, născut la Arad, continuă tradiția familiei
prin pasiunea sa pentru muzică. În 2009, descoperă arta “scratching”-ului și “sampling”-ului,
având și prima apariție în public. În 2011, începe activitatea de sound designer într-un studio de
producție din București. În anii ce urmează, își continua activitatea muzicală pe multiple scene
din Romania și din Europa, iar în prezent activează în grupul Norzeatic &amp; Qinta Sparta,
explorează muzica ethnotech sub pseudonimul Ihmai Isuav, și muzica experimentală, idm și
techno sub pseudonimul Kaius Leppah.`,
            image: '',
            link: 'https://www.youtube.com/watch?v=ObvYyJ8choE',
            email: 'mihai.vasiu@gmail.com'
          }
        ],
      },
      festival: {
        title: 'Experimental-Freeimprovised Bucharest Music Festival',
        p1: 'Ați vrea să definiți arta muzicală a viitorului? :”Ea sfidează orice definiție. Aș putea să o descriu ca un sistem puternic de aeronautică spirituală, menită să înalțe sufletul până la regiunile sublime ale armoniei supersenzoriale, deasupra constrângerilor telurice grosiere ale formelor prestabilite în compoziție și dincolo de atracțiile vulgare ale melodiei susținute”.un comentariu al d nei Joseph Bennet, în revista londoneză Musical Times din 1877- - Viețile Marilor Compozitori - Harold C. Schonberg pg.271 ed. Lider 1970',
        p2: 'John Cage nu s-a consumat pentru ca de fapt, lui încă nu i-a venit timpul. El a anunțat o nouă paradigmă artistică ce urmează să apară. John Cage a aruncat buzduganul pentru a pregăti apariția unui nou mod de a crea care se ciocneste puternic de vechile modele. A fost un deschizător de drumuri pe termen lung, un „dătător de idei”, un transformator al viziunii asupra artei și muzicii, un caz controversat care încă pune probleme de acceptare (pornind chiar de la afirmația: Everything we do is music). Conceptele legate de numele său sunt: nedeterminare, aleatorism, „chance music”, liniște plină (celebra piesă 4’33” fiind o lecție de ascultare activă), performance art, noise music, grafism, muzică electroacustică, live electronics, instrumente alternative.',
        p3: 'Un asemenea salt în percepție este mai întâi vestit de un vizionar, pentru ca mai apoi, să se manifeste, să fie acceptat și recunoscut. La început, acel vestitor va fi blamat, ucis prin ignorare fățisă, ocolit. Arta-experiență, creația vie, hibridizarea ce desființează granițe sunt încă în stare născândă, dacă privești lucrurile dintr-un punct mai înalt, care îți permite să ai o perspectivă mai largă asupra evoluției lucrurilor.',
        p4: 'Ori de cite ori s-a încercat concilierea inteligenței cu sensibilitatea, a subzistat numai inteligența și astfel s-a ajuns la uscăciune. Este neapărat nevoie ca mâna artistului să înainteze în necunoscut, ea trebuie să păstreze sentimentul pericolelor ce o pândesc, să simtă prăpastia. Imaginați-vă o mână care calchiază: știe exact unde merge; si pentru că știe drumul, este lipsită de viață. Trebuie să se întîmple exact contrariul, ductul trebuie să fie viu, si atunci este ireversibil.',
      },
      contact: {
        title: 'Contact',
        p: `Ne puteți contacta la adresa: <b>andreibalan.art@gmail.com</b>`,
        p2: 'Suntem și pe facebook:'
      }
    },
    en: {
      about: {
        mainTitle: 'Textural Music Festival',
        title: ' About Textural',
        p1: 'TEXTURAL was initiated by two niche musicians from Bucharest, Andrei Bălan and Călin Torsan. The former is also a sculptor, and the latter is also a writer. Their idea was to create a space for musicians with similar interests, who are constantly exploring (with) sound.',
        p2: 'The first edition of TEXTURAL was hosted by Piscu School Museum, in Ilfov County. The event took place between November 18-19, 2022, and brought together nine musicians. On this occasion, a unique feature of the festival was established: the random drawing of instrumental combinations for each evening.',
        p3: 'The next two editions, one in 2023 and the spring TEXTURAL of 2024, were hosted by Zadar Bar, a Bucharest bar dedicated to experimental and freely improvised music.',
      },
      program: {
        title: `TEXTURAL 4`,
        date: `(October 31 - November 3 , 2024)`,
        location: `at ZADAR BAR`,
        days: [
          {date: `Thursday, October 31`, bands: `Ariadna Ene Iliescu (viola, vocals) </br> Aram Shelton (saxophone) </br> Juli Dima (guitar) </br> Marius Achim (drums)`},
          {date: `Friday, November 1`, bands: `Sian Brie (guitar) </br> Bogdan Frigioiu (guitar) </br> Sorin Romanescu (guitar) </br> Neon Cola (electronics)`},
          {date: `Saturday, November 2`, bands: `Shanyio (exotic string instruments, prepared) </br> Andrei Raicu (electronics) </br> Medoia (guitar) </br> Ati (percussion)`},
          {date: `Sunday, November 3`, bands: `Irinel Anghel &amp; TEXTURAL ORCHESTRA`},
        ]
      },
      zadar: {
        title: 'Zadar Bar',
        p1: 'Two editions of the TEXTURAL festival were hosted by Zadar Bar: one at the end of 2023 and springTEXTURAL, a special leap year edition (2024). Reconfigured from the older structure of the former Geo Bar, the place is the dream and achievement of two passionate music lovers dedicated to unconventional sounds: Victor Hariton and Vali Neagu.',
        p2: `Everything about Zadar's music—its stage and weekly atmosphere—revolves around experimentation, novelty, improvisation, and a spirit that promotes freedom of expression and tolerance.`,
        p3: `Zadar Bar is located on Mircea Vulcănescu Street in Bucharest, at number 23. Concerts are held indoors, until a maximum of 10:00 PM, but there's also a courtyard with plenty of tables where the music can be heard through a sound system. The bar has become the main meeting spot for Bucharest's bohemian crowd and something of a ground zero for niche artists. An interesting detail: even one of the bartenders is a highly appreciated hip-hop and rap artist.`,
        p4: `During the colder season, concerts happen frequently, with the pace slowing down during the summer. Many foreign artists visiting Bucharest seek to arrange a show at this venue.`,
        p5: `On August 24, 2023, Zadar Bar was awarded the Jurjac Enescu Trophy, a statuette that can be seen on one of the bar's shelves, marking its role as a pioneer of new musical paths in Romania.`,
      },
      texturalArtists: {
        title: 'Textural Artists',
        artists: [
          {
            name: 'Alexandru Hegyesi',
            about: 'Alexandru Hegyesi is a self-taught multi-instrumentalist from Timișoara. He plays the cimbalom, kantele, dulcimer, Transylvanian & Bavarian zither, and more. He began his musical journey in 1992, playing bass guitar and percussion.',
            image: './images/1.jpg',
            link: 'https://www.youtube.com/watch?v=9hwfwTPwEYA&amp;t=950s',
            email: 'alexhegyesi74@gmail.com'
          },
          {
            name: 'Andrei Bălan',
            about: 'Andrei Bălan is one of the initiators of TEXTURAL. Both a sculptor and a musician, he improvises with guitar and electronics, creating cinematic soundscapes, drones, densities, and shimmering sound forms. He is the inventor of a sculptural musical instrument that bears his name: the Bălanophone.',
            image: './images/2.jpg',
            link: 'https://www.youtube.com/watch?v=xelvrCh2Fec',
            email: 'andreibalan.art@gmail.com'
          },
          {
            name: ' Aram Shelton',
            about: 'Aram Shelton is an improviser and composer who performs on saxophone, clarinets and live electronics. With a distinct, harmonically rich sound, he develops musical thoughts through constant motion, clear melodic and rhythmic constructs, tonal shifts and complex sounds. His vocabulary utilizes a wide range of extended techniques including multiphonics and harmonics, circular breathing, wind and an exploration of the outer ranges of the instrument.Shelton has been a member of the creative music communities in the San Francisco Bay Area, Chicago, Copenhagen and Budapest. His playing and music appears on more than two dozen albums on labels including Clean Feed, Delmark, Not Two, Astral Spirits and his own Singlespeed Music.',
            image: './images/3.jpg',
            link: 'https://downtownmusic.net/aram-shelton',
            email: 'aramshelton@gmail.com'
          },
          {
            name: 'Bogdan Frigioiu',
            about: `Guitarist and guttural vocalist, known by several pseudonyms such as Jay or “Spurcăciune” (“Filth”), also known for his projects: Rotheads, Void Forger, Cold Brats, and Keeng Ra'at.`,
            image: './images/4.jpg',
            link: 'https://www.youtube.com/watch?v=wXq-YZz4fLs',
            email: 'Bfrigioiu@gmail.com'
          },
          {
            name: 'Călin Torsan',
            about: 'One of the initiators of the TEXTURAL Festival. He improvises on wind instruments in various forms of expression and artistic direction. He has also published several literary works.',
            image: './images/5.jpeg',
            link: 'https://www.youtube.com/watch?v=RTwoIIl7r4k',
            email: 'calintorsan@gmail.com'
          },
          {
            name: 'Cătălin Medoia',
            about: 'Transitioning early from engineer to entrepreneur, and then to marketing professional, Cătălin Medoia has always been active in the music scene. Among other roles, he has been a rock concert and festival organizer, host of many jam-session nights, and a jury member for rock band competitions. Always passionate about music, Medoia has been and continues to be an admirer and promoter of the sonic (and beyond) universe of Frank Zappa.',
            about2: 'He loves the unconventional, the uniqueness of live happenings, experimentation, and improvised music. Medoia doesn’t consider himself a guitarist but rather someone who uses the electric guitar to spontaneously express his musical thoughts.',
            image: './images/6.jpg',
            link: 'https://soundcloud.com/neoncola/agree-to-disagree-live-mixtape-time-is-an-agreed-upon-construct-part-1-27-apr-zadar?ref=clipboard&amp;p=a&amp;c=0&amp;si=227d474ad9f244f38d98d68b22dcb3a3&amp;utm_source=clipboard&amp;utm_medium=text&amp;utm_campaign=social_sharing',
            email: 'catalin.medoia@gmail.com'
          },
          {
            name: 'Irinel Anghel',
            about: 'Composer, vocal performer, interdisciplinary artist (b. Bucharest, 1969). She graduated from "George Enescu" High School of Music in Bucharest, piano section (1988), and studied at National University of Music in Bucharest, both in the Musicology section (1994) and in the Composition section (1996). She has a degree in Musicology (1995) and Composition (1997), and a PhD in musical sciences. She is also a graduate of the postdoctoral program (MIDAS) at UNMB (2013).',
            about2: 'Her musical creation is composed of instrumental works, chamber music, electronics, symphonic works and theater music. Lately, her activity has been dedicated to improvised music, interdisciplinary, hybrid, fusion and performance art events. She is a follower of experiential art with surrealist touches.',
            image: './images/7.jpg',
            link: 'https://www.youtube.com/watch?v=I5ntKszoTgU',
            email: 'irinel.anghel@gmail.com'
          },
          {
            name: 'Ariadna Ene-Iliescu',
            about: `Ariadna Ene-Iliescu studied composition and orchestral conducting at the National University of Music in Bucharest. She plays the piano, sometimes the viola, violin, and other instruments. Music has been close to her for as long as she can remember and represents for her both a means of expression and a field for exploration, experimentation, and play. She explores improvisation alone or within the collectives "Al Ehtifal" and "The Mad Haterpillars". Since 2022, she has been running the educational program "Musical Theater Laboratory", in which middle school students are challenged to improvise and create both musically and dramatically.`,
            about2: '',
            image: './images/ariadna-ene.jpg',
            link: '',
            email: 'ariadnaei@yahoo.com'
          },
          {
            name: 'Iulian Dima',
            about: 'Ever since he took up the guitar at the age of 16, music, to him, has resembled a journey full of adventures, full of the unexpected, of bitter disappointments, but also of magical moments that no other art form can offer. Music gave Iulian the chance to meet talented people with great stories. He says that he does not regret anything, and that he would do it all over again, and would even recommend all parents to have their children learn a musical instrument, no matter which. Music is an experience that opens your mind, soothes your soul and unleashes your imagination and creativity.',
            image: './images/8.jpeg',
            link: 'https://www.youtube.com/watch?v=tss6ZZPbWHE',
            email: '77julian@gmail.com'
          },
          {
            name: 'Juan Carlos Negretti',
            about: 'With music and architecture at the center of his concerns and accumulating over 20 years of experience as a percussionist and drummer, Juan Carlos can be described as an architect of the rhythm. In his playing style, playful and eclectic, the listener will be able to notice the native Afro-Caribbean influences, which merge with the Balkan and Oriental ones, along with an openness to the experimental.',
            image: './images/9.jpg',
            link: 'https://www.youtube.com/watch?v=nJjxr-SGPao',
            email: 'juannegretti@hotmail.com'
          },
          {
            name: 'Marius Achim',
            about: 'He started his artistic activity in 1987, as a drummer in a high school band. After 1989, he collaborated with various music projects in different genres: country, rock, hard rock, metal, jazz and free improvisation.',
            image: './images/10.jpg',
            link: 'https://www.youtube.com/watch?v=CdAmzepCyPw',
            email: 'Achim180@gmail.com'
          },
          {
            name: 'Mihai Iordache',
            about: `Saxophonist, composer. <br><br>
            1987 - debut at Costinești Jazz Festival, blockflöte. <br>
            1987-1989 - improvisation lessons with pianist Marius Popp.</b>. <br>
            1990 - the first decent saxophone, the first jazz group with  <b>Pedro Negrescu</b> - double bass, <b>Lucian Maxim</b> - drums. Lessons with saxophonist <b>Garbis Dedeian</b>. <br>
            1993-1999 - attempt to break through the blockade of pop-rock music with the band <b>Sarmalele Reci</b>. Albums: 1995, 1996, 1999. <br>
            1999-2003 - food comitements. <br>
            1999 - Axis Mundi album , with <b>Harry Tavitian</b> and the Orient Express. <br>
            2003 - the first own jazz album: Friday, featuring <b>Tom Smith</b> <br>
            2003 - co-opted into the <b>Kumm</b> formation. <br>
            2019 - co-opted in <b>Ploiești Big Band Jazz Society</b>, on baritone saxophone <br>
            2004, 2005, 2012, 2013, 2014, 2019, 2022 - own jazz albums. <br>
            2004, 2006, 2009, 2015 - albums with <b>Kumm</b>.
          `,
            image: './images/11.jpg',
            link: 'https://www.youtube.com/watch?v=UApvn5poBLM',
            email: 'iordachemihaidan@gmail.com'
          },
          {
            name: 'Teodor Horea',
            about: 'Under the pseudonym Neon Cola, Teodor Horea composes film scores, electronic, electroacoustic and spectral music. He is synthesizer and live improviser of free jazz and progressive rock, using piano and synthesizers. He can also be described as a sound designer and producer in the niche of experimental music, currently establishing a dedicated recording studio.',
            image: './images/12.jpg',
            link: 'https://soundcloud.com/neoncola/agree-to-disagree-live-mixtape-time-is-an-agreed-upon-construct-part-1-27-apr-zadar?ref=clipboard&amp;p=a&amp;c=1&amp;si=40f9fb1c6100499ea1ac9f295cf70477&amp;utm_source=clipboard&amp;utm_medium=text&amp;utm_campaign=social_sharing',
            email: 'hr_teodor@yahoo.com'
          },
          {
            name: 'Sian Brie',
            about: 'After studying at the Bucharest Conservatory, where he was introduced to jazz and free jazz by the pianist Mircea Tiberian, Sian Brie moved to Berlin. Here he has the opportunity to meet and collaborate with important musicians from the Berlin scene, such as Christian Lillinger, Horst Nonnenmacher or Manuel Miethe. After a year in Berlin, Sian Brie goes to London, where he meets Polish trumpeter Dawid Frydryk. The two discover that the language barrier can be overcome through the common vocabulary of the expat experience coming from the former communist bloc. Musicians encounter both negative and positive discrimination and finally realize that they have come to behave in perfect accordance with the label that was given to them, hence the attitude expressed by the phrase "to live up to your discrimination standards" was born. Sian Brie and Dawid Frydryk form an improvised music trio with Guillaume Viltard. At the same time, Sian Brie is the founder of the avant-garde group UMAMI, which includes John Edwards, Mark Sanders and Dawid Frydryk, and is a member of the Bucharest-based experimental music group, And then This!. Other musicians he collaborates with include Mircea Tiberian, Maurice de Martin, Michael Griener, Mihai Iordache, etc.',
            image: './images/13.jpg',
            link: 'https://www.youtube.com/watch?v=v53WP11-RwI',
            email: 'sianbrie@gmail.com'
          },
          {
            name: 'Sorin Romanescu',
            about: `His debut as a jazz musician took place in the early 90s. After a short concert activity, after graduating a classical guitar course at the Popular Art School in Bucharest and from the pianist Marius Popp's improvisation course, from the same school, he decided to dedicate his activity, first of all, to music of jazz and improvisation. Throughout his career he has played with musicians from all generations, from pianist Marius Popp and saxophonist Garbis Dedeian (this collaboration established Sorin as a jazz musician), to Luiza Zan, Maria Răducanu, Mihai Iordache, Vlad Popescu, Cristian Soleanu and many others. Showing stylistic flexibility and having a great passion for improvisation, in the early 2000s he collaborated with choreographic artists from the area of contemporary dance, such as Vava Ștefănescu, Eduard Gabia or Mihai Mihalcea. He has been performing and recording on Alexandru Andries' albums for a long time. He also worked as an audio producer in advertising, and made several documentary film soundtracks. He also did the soundtrack for Dogs (2016, r. Bogdan Mirică).`,
            image: './images/14.jpeg',
            link: 'https://www.youtube.com/watch?app=desktop&amp;v=c-cRbkUmPv4&amp;t=194s',
            email: 'sorinromanescu@gmail.com'
          },
          {
            name: 'Mihai Vasiu',
            about: `Mihai Vasiu, grandson of the ethnomusicologist Caius Lepa, born in Arad, continues the family tradition through his passion for music. 
In 2009, he discovered the art of "scratching" and "sampling", also having his 
first public appearance back then. In 2011, he started working as a sound 
designer in a production studio in Bucharest. In the following years, he 
continued his musical activity on multiple stages in Romania and Europe, 
and currently activates in the Norzeatic &amp Qinta Sparta group. He explores ethnotech music under the pseudonym Ihmai Isuav, and experimental music, IDM and techno under the pseudonym Kaius Leppah.`,
            image: '',
            link: 'https://www.youtube.com/watch?v=ObvYyJ8choE',
            email: 'mihai.vasiu@gmail.com'
          }
        ],
      },
      festival: {
        title: 'Experimental-<b>Freely improvised</b> Bucharest Music Festival',
        p1: `Would you like to define the musical art of the future? ”She defies any definition. I might describe it as a powerful system of spiritual aeronautics, designed to elevate the soul to the sublime regions of supersensory harmony, above the coarse telluric constraints of pre-set forms in composition and beyond the vulgar attractions of sustained melody.” a comment by Ms. Joseph Bennet, in the London Musical Times magazine from 1877- - Lives of the Great Composers - Harold C. Schonberg pg.271 ed. Leader 1970`,
        p2: `John Cage has not consumed himself because, in fact, his time has not yet come. He announced a new artistic paradigm to emerge. John Cage threw down the mace to prepare the emergence of a new way of creating that collides strongly with the old models. He was a trailblazer in the long term, a "giver of ideas", a transformer of the vision of art and music, a controversial case that still poses problems of acceptance (starting from the very statement: Everything we do is music). The concepts related to his name are: indeterminacy, aleatorism, "chance music", full silence (the famous piece 4'33" being a lesson in active listening), performance art, noise music, graphism, electroacoustic music, live electronics, alternative instruments.`,
        p3: 'Such a leap in perception is first heralded by a visionary, so that later it manifests itself, is accepted and recognized. At first, that herald will be blamed, killed by blatant ignorance, shunned. Experiential art, living creation, boundary-breaking hybridization are still in their nascent state, if you look at things from a higher point that allows you to have a broader perspective on the evolution of things.',
        p4: `Whenever the reconciliation of intelligence with sensibility was attempted, only intelligence remained, and thus dryness was reached. It is absolutely necessary for the artist's hand to advance into the unknown, she must keep the sense of the dangers that lurk, feel the abyss. Imagine a hand that is ironing: it knows exactly where it is going; and because she knows the way, she is lifeless. The exact opposite must happen, the duct must be alive, and then it is irreversible.`,
      },
      contact: {
        title: 'Contact',
        p: `You can contact us at: <b>andreibalan.art@gmail.com</b>`,
        p2: 'We are also on facebook:'
      }
    },
    fr: {
      about: {
        mainTitle: 'Festival de Musique Texturale',
        title: 'À propos de Textural',
        p1: 'TEXTURAL a été initié par deux musiciens de niche de Bucarest, Andrei Bălan et Călin Torsan. Le premier est également sculpteur, et le second est également écrivain. Leur idée était de créer un espace pour les musiciens aux intérêts similaires, qui explorent constamment (avec) le son.',
        p2: `La première édition de TEXTURAL a été accueillie par le Musée de l'École Piscu, dans le comté d'Ilfov. L'événement a eu lieu entre le 18 et le 19 novembre 2022, et a réuni neuf musiciens. À cette occasion, une caractéristique unique du festival a été établie : le tirage au sort aléatoire des combinaisons instrumentales pour chaque soirée.`,
        p3: 'Les deux éditions suivantes, une en 2023 et le TEXTURAL de printemps 2024, ont été accueillies par le Zadar Bar, un bar de Bucarest dédié à la musique expérimentale et librement improvisée.',
      },
      program: {
        title: 'TEXTURAL 4',
        date: '(31 octobre - 3 novembre 2024)',
        location: 'au ZADAR BAR',
        days: [
          {date: 'Jeudi 31 octobre', bands: 'Ariadna Ene Iliescu (alto, voix) </br> Aram Shelton (saxophone) </br> Juli Dima (guitare) </br> Marius Achim (batterie)'},
          {date: 'Vendredi 1er novembre', bands: 'Sian Brie (guitare) </br> Bogdan Frigioiu (guitare) </br> Sorin Romanescu (guitare) </br> Neon Cola (électronique)'},
          {date: 'Samedi 2 novembre', bands: 'Shanyio (instruments à cordes exotiques, préparés) </br> Andrei Raicu (électronique) </br> Medoia (guitare) </br> Ati (percussion)'},
          {date: 'Dimanche 3 novembre', bands: 'Irinel Anghel &amp; ORCHESTRE TEXTURAL'},
        ]
      },
      zadar: {
        title: 'Zadar Bar',
        p1: `Deux éditions du festival TEXTURAL ont été accueillies par le Zadar Bar : une à la fin de 2023 et le TEXTURAL de printemps, une édition spéciale année bissextile (2024). Reconfiguré à partir de l'ancienne structure de l'ancien Geo Bar, le lieu est le rêve et la réalisation de deux passionnés de musique dédiés aux sons non conventionnels : Victor Hariton et Vali Neagu.`,
        p2: `Tout dans la musique de Zadar - sa scène et son atmosphère hebdomadaire - tourne autour de l'expérimentation, de la nouveauté, de l'improvisation et d'un esprit qui promeut la liberté d'expression et la tolérance.`,
        p3: `Le Zadar Bar est situé rue Mircea Vulcănescu à Bucarest, au numéro 23. Les concerts se déroulent à l'intérieur, jusqu'à 22h00 maximum, mais il y a aussi une cour avec de nombreuses tables où la musique peut être entendue grâce à un système sonore. Le bar est devenu le principal point de rencontre de la foule bohème de Bucarest et une sorte de ground zero pour les artistes de niche. Un détail intéressant : même l'un des barmen est un artiste hip-hop et rap très apprécié.`,
        p4: `Pendant la saison froide, les concerts sont fréquents, le rythme ralentissant pendant l'été. De nombreux artistes étrangers visitant Bucarest cherchent à organiser un spectacle dans ce lieu.`,
        p5: `Le 24 août 2023, le Zadar Bar a reçu le Trophée Jurjac Enescu, une statuette que l'on peut voir sur l'une des étagères du bar, marquant son rôle de pionnier des nouvelles voies musicales en Roumanie.`,
      },
      texturalArtists: {
        title: 'Artistes Texturaux',
        artists: [
          {
            name: 'Alexandru Hegyesi',
            about: 'Alexandru Hegyesi est un multi-instrumentiste autodidacte de Timișoara. Il joue du cymbalum, du kantele, du dulcimer, de la cithare transylvaine et bavaroise, et plus encore. Il a commencé son parcours musical en 1992, jouant de la basse et des percussions.',
            image: './images/1.jpg',
            link: 'https://www.youtube.com/watch?v=9hwfwTPwEYA&amp;t=950s',
            email: 'alexhegyesi74@gmail.com'
          },
          {
            name: 'Andrei Bălan',
            about: 'Andrei Bălan est l\'un des initiateurs de TEXTURAL. À la fois sculpteur et musicien, il improvise avec guitare et électronique, créant des paysages sonores cinématographiques, des drones, des densités et des formes sonores scintillantes. Il est l\'inventeur d\'un instrument musical sculptural qui porte son nom : le Bălanophone.',
            image: './images/2.jpg',
            link: 'https://www.youtube.com/watch?v=xelvrCh2Fec',
            email: 'andreibalan.art@gmail.com'
          },
          {
            name: 'Aram Shelton',
            about: 'Aram Shelton est un improvisateur et compositeur qui joue du saxophone, des clarinettes et de l\'électronique live. Avec un son distinct et harmoniquement riche, il développe des pensées musicales à travers un mouvement constant, des constructions mélodiques et rythmiques claires, des changements tonaux et des sons complexes. Son vocabulaire utilise une large gamme de techniques étendues incluant les multiphoniques et les harmoniques, la respiration circulaire, le souffle et une exploration des registres extérieurs de l\'instrument. Shelton a été membre des communautés de musique créative dans la région de la baie de San Francisco, Chicago, Copenhague et Budapest. Son jeu et sa musique apparaissent sur plus de deux douzaines d\'albums sur des labels comme Clean Feed, Delmark, Not Two, Astral Spirits et son propre label Singlespeed Music.',
            image: './images/3.jpg',
            link: 'https://downtownmusic.net/aram-shelton',
            email: 'aramshelton@gmail.com'
          },
          {
            name: 'Bogdan Frigioiu',
            about: 'Guitariste et chanteur guttural, connu sous plusieurs pseudonymes tels que Jay ou "Spurcăciune" ("Saleté"), également connu pour ses projets : Rotheads, Void Forger, Cold Brats et Keeng Ra\'at.',
            image: './images/4.jpg',
            link: 'https://www.youtube.com/watch?v=wXq-YZz4fLs',
            email: 'Bfrigioiu@gmail.com'
          },
          {
            name: 'Călin Torsan',
            about: 'L\'un des initiateurs du Festival TEXTURAL. Il improvise sur des instruments à vent dans diverses formes d\'expression et de direction artistique. Il a également publié plusieurs œuvres littéraires.',
            image: './images/5.jpeg',
            link: 'https://www.youtube.com/watch?v=RTwoIIl7r4k',
            email: 'calintorsan@gmail.com'
          },
          {
            name: 'Cătălin Medoia',
            about: 'Passant rapidement d\'ingénieur à entrepreneur, puis à professionnel du marketing, Cătălin Medoia a toujours été actif dans la scène musicale. Entre autres rôles, il a été organisateur de concerts et festivals rock, animateur de nombreuses soirées jam-session, et membre du jury pour des compétitions de groupes rock. Toujours passionné de musique, Medoia a été et continue d\'être un admirateur et promoteur de l\'univers sonore (et au-delà) de Frank Zappa.',
            about2: 'Il aime l\'non-conventionnel, l\'unicité des événements live, l\'expérimentation et la musique improvisée. Medoia ne se considère pas comme un guitariste mais plutôt comme quelqu\'un qui utilise la guitare électrique pour exprimer spontanément ses pensées musicales.',
            image: './images/6.jpg',
            link: 'https://soundcloud.com/neoncola/agree-to-disagree-live-mixtape-time-is-an-agreed-upon-construct-part-1-27-apr-zadar?ref=clipboard&amp;p=a&amp;c=0&amp;si=227d474ad9f244f38d98d68b22dcb3a3&amp;utm_source=clipboard&amp;utm_medium=text&amp;utm_campaign=social_sharing',
            email: 'catalin.medoia@gmail.com'
          },
          {
            name: 'Irinel Anghel',
            about: 'Compositrice, interprète vocale, artiste interdisciplinaire (née à Bucarest en 1969). Elle est diplômée du lycée de musique "George Enescu" à Bucarest, section piano (1988), et a étudié à l\'Université Nationale de Musique de Bucarest, à la fois dans la section Musicologie (1994) et dans la section Composition (1996). Elle est titulaire d\'un diplôme en Musicologie (1995) et en Composition (1997), ainsi que d\'un doctorat en sciences musicales. Elle est également diplômée du programme postdoctoral (MIDAS) à l\'UNMB (2013).',
            about2: 'Sa création musicale se compose d\'œuvres instrumentales, de musique de chambre, d\'électronique, d\'œuvres symphoniques et de musique de théâtre. Ces derniers temps, son activité s\'est consacrée à la musique improvisée, aux événements interdisciplinaires, hybrides, de fusion et d\'art performance. Elle est adepte de l\'art expérientiel avec des touches surréalistes.',
            image: './images/7.jpg',
            link: 'https://www.youtube.com/watch?v=I5ntKszoTgU',
            email: 'irinel.anghel@gmail.com'
          },
          {
            name: 'Ariadna Ene-Iliescu',
            about: `Ariadna Ene-Iliescu a étudié la composition et la direction d'orchestre à l'Université Nationale de Musique de Bucarest. Elle joue du piano, parfois de l'alto, du violon et d'autres instruments. La musique a toujours fait partie de sa vie et représente pour elle à la fois un moyen d'expression et un terrain de recherche, d'expérimentation et de jeu. Elle explore l'improvisation seule ou au sein des collectifs "Al Ehtifal" et "The Mad Haterpillars". Depuis 2022, elle dirige le programme éducatif "Laboratoire de théâtre musical", dans lequel les élèves du collège sont invités à improviser et à créer musicalement et dramatiquement.`,
            about2: '',
            image: './images/ariadna-ene.jpg',
            link: '',
            email: 'ariadnaei@yahoo.com'
          },
          {
            name: 'Iulian Dima',
            about: 'Depuis qu\'il a pris la guitare à l\'âge de 16 ans, la musique, pour lui, ressemble à un voyage plein d\'aventures, plein d\'inattendu, de déceptions amères, mais aussi de moments magiques qu\'aucune autre forme d\'art ne peut offrir. La musique a donné à Iulian la chance de rencontrer des personnes talentueuses avec de grandes histoires. Il dit qu\'il ne regrette rien, et qu\'il recommencerait tout, et recommanderait même à tous les parents de faire apprendre un instrument de musique à leurs enfants, peu importe lequel. La musique est une expérience qui ouvre l\'esprit, apaise l\'âme et libère l\'imagination et la créativité.',
            image: './images/8.jpeg',
            link: 'https://www.youtube.com/watch?v=tss6ZZPbWHE',
            email: '77julian@gmail.com'
          },
          {
            name: 'Juan Carlos Negretti',
            about: 'Avec la musique et l\'architecture au centre de ses préoccupations et accumulant plus de 20 ans d\'expérience en tant que percussionniste et batteur, Juan Carlos peut être décrit comme un architecte du rythme. Dans son style de jeu, ludique et éclectique, l\'auditeur pourra remarquer les influences afro-caribéennes natives, qui fusionnent avec celles des Balkans et de l\'Orient, ainsi qu\'une ouverture à l\'expérimental.',
            image: './images/9.jpg',
            link: 'https://www.youtube.com/watch?v=nJjxr-SGPao',
            email: 'juannegretti@hotmail.com'
          },
          {
            name: 'Marius Achim',
            about: 'Il a commencé son activité artistique en 1987, en tant que batteur dans un groupe de lycée. Après 1989, il a collaboré avec divers projets musicaux dans différents genres : country, rock, hard rock, metal, jazz et improvisation libre.',
            image: './images/10.jpg',
            link: 'https://www.youtube.com/watch?v=CdAmzepCyPw',
            email: 'Achim180@gmail.com'
          },
          {
            name: 'Mihai Iordache',
            about: `Saxophoniste, compositeur. <br><br>
            1987 - début au Festival de Jazz de Costinești, flûte à bec. <br>
            1987-1989 - cours d'improvisation avec le pianiste Marius Popp.</b>. <br>
            1990 - le premier saxophone décent, le premier groupe de jazz avec <b>Pedro Negrescu</b> - contrebasse, <b>Lucian Maxim</b> - batterie. Cours avec le saxophoniste <b>Garbis Dedeian</b>. <br>
            1993-1999 - tentative de percer le blocus de la musique pop-rock avec le groupe <b>Sarmalele Reci</b>. Albums : 1995, 1996, 1999. <br>
            1999-2003 - engagements alimentaires. <br>
            1999 - Album Axis Mundi, avec <b>Harry Tavitian</b> et l'Orient Express. <br>
            2003 - le premier album de jazz personnel : Friday, avec <b>Tom Smith</b> <br>
            2003 - coopté dans la formation <b>Kumm</b>. <br>
            2019 - coopté dans <b>Ploiești Big Band Jazz Society</b>, au saxophone baryton <br>
            2004, 2005, 2012, 2013, 2014, 2019, 2022 - albums de jazz personnels. <br>
            2004, 2006, 2009, 2015 - albums avec <b>Kumm</b>.
          `,
            image: './images/11.jpg',
            link: 'https://www.youtube.com/watch?v=UApvn5poBLM',
            email: 'iordachemihaidan@gmail.com'
          },
          {
            name: 'Teodor Horea',
            about: 'Sous le pseudonyme de Neon Cola, Teodor Horea compose des bandes originales de films, de la musique électronique, électroacoustique et spectrale. Il est synthétiseur et improvisateur live de free jazz et de rock progressif, utilisant piano et synthétiseurs. Il peut aussi être décrit comme un sound designer et producteur dans la niche de la musique expérimentale, établissant actuellement un studio d\'enregistrement dédié.',
            image: './images/12.jpg',
            link: 'https://soundcloud.com/neoncola/agree-to-disagree-live-mixtape-time-is-an-agreed-upon-construct-part-1-27-apr-zadar?ref=clipboard&amp;p=a&amp;c=1&amp;si=40f9fb1c6100499ea1ac9f295cf70477&amp;utm_source=clipboard&amp;utm_medium=text&amp;utm_campaign=social_sharing',
            email: 'hr_teodor@yahoo.com'
          },
          {
            name: 'Sian Brie',
            about: 'Après avoir étudié au Conservatoire de Bucarest, où il a été initié au jazz et au free jazz par le pianiste Mircea Tiberian, Sian Brie s\'est installé à Berlin. Ici, il a l\'opportunité de rencontrer et de collaborer avec d\'importants musiciens de la scène berlinoise, tels que Christian Lillinger, Horst Nonnenmacher ou Manuel Miethe. Après un an à Berlin, Sian Brie part pour Londres, où il rencontre le trompettiste polonais Dawid Frydryk. Les deux découvrent que la barrière de la langue peut être surmontée grâce au vocabulaire commun de l\'expérience d\'expatrié venant de l\'ancien bloc communiste. Les musiciens rencontrent à la fois une discrimination négative et positive et réalisent finalement qu\'ils en sont venus à se comporter en parfait accord avec l\'étiquette qui leur a été donnée, d\'où est née l\'attitude exprimée par la phrase "être à la hauteur de vos normes de discrimination". Sian Brie et Dawid Frydryk forment un trio de musique improvisée avec Guillaume Viltard. En même temps, Sian Brie est le fondateur du groupe avant-gardiste UMAMI, qui comprend John Edwards, Mark Sanders et Dawid Frydryk, et est membre du groupe de musique expérimentale basé à Bucarest, And then This!. Parmi les autres musiciens avec lesquels il collabore, on trouve Mircea Tiberian, Maurice de Martin, Michael Griener, Mihai Iordache, etc.',
            image: './images/13.jpg',
            link: 'https://www.youtube.com/watch?v=v53WP11-RwI',
            email: 'sianbrie@gmail.com'
          },
          {
            name: 'Sorin Romanescu',
            about: 'Ses débuts en tant que musicien de jazz ont eu lieu au début des années 90. Après une courte activité de concert, après avoir obtenu son diplôme d\'un cours de guitare classique à l\'École d\'Art Populaire de Bucarest et du cours d\'improvisation du pianiste Marius Popp, de la même école, il a décidé de consacrer son activité, avant tout, à la musique de jazz et d\'improvisation. Tout au long de sa carrière, il a joué avec des musiciens de toutes générations, du pianiste Marius Popp et du saxophoniste Garbis Dedeian (cette collaboration a établi Sorin comme musicien de jazz), à Luiza Zan, Maria Răducanu, Mihai Iordache, Vlad Popescu, Cristian Soleanu et bien d\'autres. Faisant preuve de flexibilité stylistique et ayant une grande passion pour l\'improvisation, au début des années 2000, il a collaboré avec des artistes chorégraphiques du domaine de la danse contemporaine, tels que Vava Ștefănescu, Eduard Gabia ou Mihai Mihalcea. Il se produit et enregistre depuis longtemps sur les albums d\'Alexandru Andries. Il a également travaillé comme producteur audio dans la publicité et a réalisé plusieurs bandes sonores de films documentaires. Il a aussi fait la bande sonore de Dogs (2016, r. Bogdan Mirică).',
            image: './images/14.jpeg',
            link: 'https://www.youtube.com/watch?app=desktop&amp;v=c-cRbkUmPv4&amp;t=194s',
            email: 'sorinromanescu@gmail.com'
          },
          {
            name: 'Mihai Vasiu',
            about: `Mihai Vasiu, petit-fils de l'ethnomusicologue Caius Lepa, né à Arad, poursuit la tradition familiale à travers sa passion pour la musique. 
En 2009, il a découvert l'art du "scratching" et du "sampling", faisant également sa 
première apparition publique à cette époque. En 2011, il a commencé à travailler comme sound 
designer dans un studio de production à Bucarest. Au cours des années suivantes, il 
a poursuivi son activité musicale sur de multiples scènes en Roumanie et en Europe, 
et active actuellement dans le groupe Norzeatic &amp Qinta Sparta. Il explore la musique ethnotech sous le pseudonyme Ihmai Isuav, et la musique expérimentale, l'IDM et la techno sous le pseudonyme Kaius Leppah.`,
            image: '',
            link: 'https://www.youtube.com/watch?v=ObvYyJ8choE',
            email: 'mihai.vasiu@gmail.com'
          }
        ]
      },
      festival: {
        title: 'Festival de Musique Expérimentale-<b>Librement improvisée</b> de Bucarest',
        p1: `Voulez-vous définir l'art musical du futur ? "Elle défie toute définition. Je pourrais la décrire comme un puissant système d'aéronautique spirituelle, conçu pour élever l'âme vers les régions sublimes de l'harmonie supersensorielle, au-dessus des grossières contraintes telluriques des formes préétablies dans la composition et au-delà des attractions vulgaires de la mélodie soutenue." un commentaire de Mme Joseph Bennet, dans le magazine London Musical Times de 1877 - Lives of the Great Composers - Harold C. Schonberg p.271 éd. Leader 1970`,
        p2: `John Cage ne s'est pas consumé car, en fait, son heure n'est pas encore venue. Il a annoncé l'émergence d'un nouveau paradigme artistique. John Cage a jeté le gant pour préparer l'émergence d'une nouvelle façon de créer qui entre en collision frontale avec les anciens modèles. Il était un précurseur à long terme, un "donneur d'idées", un transformateur de la vision de l'art et de la musique, un cas controversé qui pose encore des problèmes d'acceptation (à commencer par l'affirmation même : Tout ce que nous faisons est musique). Les concepts liés à son nom sont : l'indétermination, l'aléatoire, la "musique du hasard", le silence complet (la célèbre pièce 4'33" étant une leçon d'écoute active), l'art performatif, la musique noise, le graphisme, la musique électroacoustique, l'électronique live, les instruments alternatifs.`,
        p3: `Un tel bond dans la perception est d'abord annoncé par un visionnaire, pour qu'ensuite il se manifeste, soit accepté et reconnu. Au début, ce héraut sera blâmé, tué par une ignorance flagrante, rejeté. L'art expérientiel, la création vivante, l'hybridation qui brise les frontières sont encore à l'état naissant, si l'on regarde les choses d'un point plus élevé qui permet d'avoir une perspective plus large sur l'évolution des choses.`,
        p4: `Chaque fois que la réconciliation de l'intelligence avec la sensibilité a été tentée, seule l'intelligence est restée, et ainsi la sécheresse a été atteinte. Il est absolument nécessaire que la main de l'artiste s'avance dans l'inconnu, elle doit garder le sens des dangers qui se cachent, sentir l'abîme. Imaginez une main qui repasse : elle sait exactement où elle va ; et parce qu'elle connaît le chemin, elle est sans vie. L'exact opposé doit se produire, le conduit doit être vivant, et alors c'est irréversible.`,
      },
      contact: {
        title: 'Contact',
        p: `Vous pouvez nous contacter à : <b>andreibalan.art@gmail.com</b>`,
        p2: 'Nous sommes aussi sur Facebook :'
      }
    },
    de: {},
  }
}